import { Observable, of as observableOf } from 'rxjs';
import { AjaxResponse } from 'rxjs/ajax';
import { switchMap } from 'rxjs/operators';
import { Activity } from 'state-domains/domain/project';

import { buildConfigurationProjectActivitiesUrl } from '../../../network/urls';
import { convertToCamel, convertToSnake, sendRequestWithXSRFToken } from '../../../utils';
import { ConfigurationProjectActivityRequest } from '../types';

export const addConfigurationProjectActivity = (
    projectId: string,
    payload: ConfigurationProjectActivityRequest,
): Observable<Activity> => {
    const url = buildConfigurationProjectActivitiesUrl(projectId);
     
    delete payload.type;
    const snakePayload = convertToSnake(payload);
    return sendRequestWithXSRFToken(url, snakePayload, 'POST').pipe(
        switchMap(({ response }: AjaxResponse<Activity>) => {
            const addActivityResponse = convertToCamel<Activity>(response);
            return observableOf(addActivityResponse);
        }),
    );
};
